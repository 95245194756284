import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import { FC } from 'react'
import Balancer from 'react-wrap-balancer'
import { Box } from 'ui-v2/components/Box'
import { Text } from 'ui-v2/components/Text'
import {
  innerContainerCx,
  linkCx,
  outerContainerCx,
  svgContainerCx,
  textContainerCx,
} from './ErrorLayout.css'
import { ErrorPlaceholderSvg } from './ErrorPlaceholderSvg'

export interface ErrorLayoutProps {
  title: string
  message: string
  code: string | number
}

export const d = {
  codeMessage: 'errors:error-page.code-message',
  messageA: 'errors:error-page.action-msg-0',
  linkLocal: 'errors:error-page.action-link-0',
}
export const ErrorLayout: FC<React.PropsWithChildren<ErrorLayoutProps>> = ({
  title,
  message,
  code,
}) => {
  const { t } = useTranslation('errors')

  return (
    <div className={outerContainerCx}>
      <div className={innerContainerCx}>
        <Box className={svgContainerCx}>
          <ErrorPlaceholderSvg />
        </Box>
        <div className={textContainerCx}>
          <Text variant="displaySm" color="baseColorsPrimary9">
            <Balancer>{title}</Balancer>
          </Text>
          <Text variant="headingSm">
            <Balancer>
              {message}
              <br />
              {t(d.messageA)}
              <Link href="/" passHref className={linkCx}>
                {t(d.linkLocal)}
              </Link>
            </Balancer>
          </Text>
          <Text variant="headingSm">{t(d.codeMessage, { code })}</Text>
        </div>
      </div>
    </div>
  )
}
